<template>
  <div class="outer screen">
    <div class="center">
      <div v-if="!hasFirstName && !hasLastName && !hasEmail">
        <PersonalDataScreen />
      </div>
      <div v-if="hasFirstName && !hasQuestionOne">
        <QuestionOneScreen />
      </div>
      <div v-if="hasQuestionOne && !hasQuestionTwo">
        <QuestionTwoScreen />
      </div>
      <div v-if="hasQuestionTwo && !hasQuestionThree">
        <QuestionThreeScreen />
      </div>
      <div v-if="hasQuestionThree && !hasQuestionFour">
        <QuestionFourScreen />
      </div>
      <div v-if="hasQuestionFour && !hasQuestionGdpr">
        <QuestionGdprScreen />
      </div>
      <div v-if="hasQuestionGdpr && hasSavedData">
        <QrCodeScreen />
      </div>
      <div class="bottom-spacer"></div>
    </div>
  </div>
</template>

<script>
import PersonalDataScreen from "@/components/PersonalDataScreen.vue";
import QuestionOneScreen from "@/components/QuestionOneScreen.vue";
import QuestionTwoScreen from "@/components/QuestionTwoScreen.vue";
import QuestionThreeScreen from "@/components/QuestionThreeScreen.vue";
import QuestionFourScreen from "@/components/QuestionFourScreen.vue";
import QuestionGdprScreen from "@/components/QuestionGdprScreen.vue";
import QrCodeScreen from "@/components/QrCodeScreen.vue";

export default {
  name: "HomeView",
  components: {
    PersonalDataScreen,
    QuestionOneScreen,
    QuestionTwoScreen,
    QuestionThreeScreen,
    QuestionFourScreen,
    QuestionGdprScreen,
    QrCodeScreen,
  },
  computed: {
    hasFirstName: function () {
      if (this.$store.getters.getFirstName === "") {
        return false;
      }
      return true;
    },
    hasLastName: function () {
      if (this.$store.getters.getLastName === "") {
        return false;
      }
      return true;
    },
    hasEmail: function () {
      if (this.$store.getters.getEmail === "") {
        return false;
      }
      return true;
    },
    hasQuestionOne: function () {
      if (this.$store.getters.getQuestionOne === null) {
        return false;
      }
      return true;
    },
    hasQuestionTwo: function () {
      if (this.$store.getters.getQuestionTwo === null) {
        return false;
      }
      return true;
    },
    hasQuestionThree: function () {
      if (this.$store.getters.getQuestionThree === null) {
        return false;
      }
      return true;
    },
    hasQuestionFour: function () {
      if (this.$store.getters.getQuestionFour === null) {
        return false;
      }
      return true;
    },
    hasQuestionGdpr: function () {
      if (this.$store.getters.getQuestionGdpr === null) {
        return false;
      }
      return true;
    },
    hasSavedData: function () {
      if (this.$store.getters.getSavedData === null) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
html,
body {
  /* background-color: #fff; */
  /* text-transform: uppercase; */
}
.screen {
  /* background: url("~@/assets/logo.png") no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  overflow: hidden; /* Hide scrollbars */
}
.outer {
  /* display: flex; */
  /* align-items: center; */
}
.center {
  /* margin: auto; */
  padding: 10px;
}
.logo {
  padding: 15px;
}
.bottom-spacer {
  padding: 100px;
}
* {
  border-radius: 0 !important;
}
</style>
